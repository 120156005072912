<template>
  <div class="home">
    <!-- header -->
    <header>
      <nav class="navbar navbar-expand-lg navbar-light fixed-top">
        <div class="container-fluid">
          <a class="navbar-brand" href="/"
            ><img src="../assets/img/Landing/NewOctagonLogo.svg" alt="LOGO"
          /></a>
          <button
            class="navbar-toggler"
            type="button"
            data-mdb-toggle="collapse"
            data-mdb-target="#navbarSupportedContent"
            aria-controls="navbarSupportedContent"
            aria-expanded="false"
            aria-label="Toggle navigation"
          >
            <i class="fas fa-bars"></i>
          </button>

          <div class="collapse navbar-collapse" id="navbarSupportedContent">
            <ul class="navbar-nav me-auto mb-2 mb-lg-0"></ul>

            <div class="d-grid gap-2 d-md-flex justify-content-md-end">
              <template
                v-if="
                  this.$route.path !== '/auth/invited' &&
                    this.$route.path !== '/auth/signup-login'
                "
              >
                <ButtonGradient
                  v-if="!this.$store.getters['auth/isLoggedIn']"
                  ButtonText="Регистрация / Вход"
                  ButtonLink="/auth/signup-login"
                  :NeedIcon="true"
                />
                <ButtonGradient
                  ButtonText="Выйти"
                  ButtonLink="/"
                  :NeedIcon="true"
                  v-if="this.$store.getters['auth/isLoggedIn']"
                  @click.native="logout"
                />
                <ButtonGradient
                  :ButtonText="
                    user &&
                    user.hasOwnProperty(`organization`) &&
                    user.organization.length > 0
                      ? user.organization[0].organization_name
                      : `Мой профиль`
                  "
                  :ButtonLink="
                    user &&
                    user.hasOwnProperty(`organization`) &&
                    user.organization.length > 0
                      ? `/organization/profile`
                      : `/beta/profile`
                  "
                  :NeedIcon="true"
                  v-if="this.$store.getters['auth/isLoggedIn']"
                  @click.native="logout"
                />
              </template>
            </div>
          </div>
        </div>

        <div class="nav-mobile-version">
          <img :src="url_logo" />

          <div id="MobileNavbar" @click="openBurgerMenu = true">
            <i class="bx bx-menu burger" />
          </div>
        </div>

        <el-drawer
          v-model="openBurgerMenu"
          direction="ttb"
          modal-class="BurgerMenuButtons"
          :before-close="handleClose"
          :with-header="false"
          size="auto"
        >
          <div class="BurgerMenuButtons">
            <img class="OctagonLogo" :src="url_logo" />

            <router-link
              class="ButtonLink"
              to="/"
              @click="openBurgerMenu = false"
              >Главная</router-link
            >
          </div>
        </el-drawer>
      </nav>
    </header>
    <router-view />
    <!-- <Ask
      :email="this.user !== null ? this.user.email : null"
      :institute="this.user !== null ? this.user.university : null"
    ></Ask> -->
    <!-- footer -->
    <footer>
      <div class="footer container">
        <div class="row">
          <div class="col-lg-6 col-md-12 mb-4 mb-md-0">
            <h5 class="text-uppercase">Практика в "Форус"</h5>
            <p>
              Пройди практику в одной из крупнейших IT-компаний Сибири и
              Дальнего Востока. Напиши диплом вместе в нами. Начни свою карьеру.
              Мы открыты для новых возможностей и всегда готовы прийти на
              помощь. Ждем именно тебя!
            </p>
          </div>

          <div class="col-lg-3 col-md-6 mb-4 mb-md-0"></div>

          <div class="col-lg-3 col-md-12 mb-4 mb-md-0">
            <h5 id="contacts" class="text-uppercase">Контакты</h5>

            <ul class="list-unstyled">
              <li>
                <i class="fas fa-phone fa-lg"></i
                ><span> +7 (3952) 25-87-33</span>
              </li>
              <li>
                <i class="fas fa-map-marker-alt fa-lg"></i
                ><span> г. Иркутск, ул. Партизанская 49</span>
              </li>
              <li>
                <i class="fas fa-envelope-open-text fa-lg"></i>
                <a class="mail-link" href="students.forus@mail.ru"
                  >students.forus@mail.ru</a
                >
              </li>
            </ul>
          </div>
        </div>
      </div>
      <div class="copyright text-center">Forus © 1992-2024</div>
    </footer>
  </div>
</template>

<style scoped>
/* 
@import url(https://use.fontawesome.com/releases/v5.15.2/css/all.css); */
/* @import url(https://fonts.googleapis.com/css2?family=Roboto:wght@300;400;500;700&display=swap); */
nav {
  padding-bottom: 0px !important;
}
@media (max-width: 312px) {
  div.content-landing {
    min-width: 240px;
  }
}

@media (max-width: 991px) {
  #navbarSupportedContent > div {
    justify-content: left !important;
  }

  #navbarSupportedContent > div > a {
    color: rgba(0, 0, 0, 0.55) !important;
    border: 0 !important;
    padding-left: 0px !important;
    text-align: left;
    font-size: 1em !important;
    box-shadow: none !important;
  }

  #navbarSupportedContent > div > a:hover {
    box-shadow: none !important;
    border: 0 !important;
    color: rgba(0, 0, 0, 0.7) !important;
  }

  #navbarSupportedContent > ul {
    margin-bottom: 0 !important;
  }
}

.copyright {
  background-color: #212529 !important;
  width: 100% !important;
  padding: 10px !important;
  color: #696969;
  font-size: 0.8rem !important;
  margin-top: 15px;
}
.footer {
  display: flex;
  width: 100%;
  flex-direction: column;
  align-items: center !important;
  justify-content: center !important;
  font-weight: 300 !important ;
}

.mail-link {
  text-decoration: none;
  cursor: pointer;
  color: white;
}

.mail-link:hover {
  text-decoration: underline;
}

li {
  padding: 3px 0px;
}
i {
  padding-right: 15px;
}

footer {
  background-color: #32353a !important;
  color: white !important;
  padding-top: 40px;
  font-family: "Montserrat";
}
.enter-btn:hover {
  opacity: 0.8 !important;
  box-shadow: inset 0 0 3px #212529 !important;
}

.enter-btn {
  box-shadow: none !important;
  border: 1px solid black !important;
  font-family: "Montserrat" !important;
  color: black !important;
  padding: 0.6rem 2rem !important;
  font-size: 0.9rem !important;
  border-radius: 12px !important;
  font-weight: 600 !important ;
  text-transform: capitalize !important;
}

.navbar {
  background-color: transparent;
  box-shadow: none;
  /* box-shadow: 1px 1px 10px rgb(211, 211, 211) ; */
}
header {
  font-family: "Montserrat";
  font-weight: 600;
  font-size: 1em;
}

header > .navbar {
  /* background-color: rgb(255, 255, 255); */
  padding-top: 0px;
}

header > .navbar > div {
  margin-bottom: 10px;
  margin-top: 10px;
  margin-left: 5px;
  margin-right: 5px;
}
</style>

<style scoped>
.nav-mobile-version {
  position: fixed;

  top: 0%;
  left: 0%;

  margin: 0%;
  padding: 1rem;

  display: flex;
  flex-direction: row;
  justify-content: space-between;
  align-content: center;

  width: 100%;

  /* backdrop-filter: blur(10px); */
}

.nav-mobile-version > img {
  height: 3rem;
  width: auto;
  margin: auto;
  padding-left: 3rem;
}

.nav-mobile-version > #MobileNavbar {
  position: relative;
  width: 3rem;
  height: 3rem;
  display: flex;
  z-index: 1001;
}
.nav-mobile-version > #MobileNavbar > i {
  color: #e0e0e0;
  font-size: 2rem;
  position: relative;
  width: auto;
  height: auto;
  margin: auto;
}

.BurgerMenuButtons {
  position: relative;

  display: flex;
  flex-direction: column;
  justify-content: center;
  text-align: center;

  width: 100%;
  height: auto;

  padding: 1rem 1rem 3rem 1rem;

  background-color: rgb(20, 20, 20);
}

.BurgerMenuButtons > img.OctagonLogo {
  height: 3rem;
  margin: 4vw auto !important;
}

.BurgerMenuButtons > a.ButtonLink {
  margin: 1vw auto !important;
  font-size: 4vw;
}

.BurgerMenuButtons a.ButtonFooter {
  margin: 2vw auto !important;
  width: 60vw !important;
}
</style>

<script>
import ButtonGradient from "@/components/Landing/ButtonGradient";

import { mapGetters, mapMutations } from "vuex";
import { findById } from "@/api/user.js";
import { logout } from "@/api/auth.js";
import { refresh } from "@/api/auth.js";
// import Ask from "@/components/Ask.vue";
export default {
  name: "Layout",
  components: {
    // Ask,
    ButtonGradient,
  },
  data: () => ({
    url_logo: require("@/assets/img/Landing/NewOctagonLogo.svg"),
    url: require("@/assets/img/profile/profile-human-b.png"),
    active: true,
    openBurgerMenu: false,
  }),
  computed: {
    ...mapGetters({
      user: "auth/isLoggedIn",
    }),
  },
  async created() {
    // if (this.user === null) {
    //   const response = await refresh();
    //   const token = response.accessToken;
    //   const userResponse = response.user;
    //   localStorage.setItem("token", token);
    //   this.getUser(userResponse.id);
    // } else {
    //   this.getUser(this.user.id);
    // }
  },
  mounted() {
    if (this.$route.query.activated && this.$route.query.activated == 1) {
      this.$message({
        message: "Email успешно активирован!",
        duration: 5000,
        showClose: true,
        type: "success",
      });
    }
  },
  methods: {
    ...mapMutations("auth", ["SET_USER"]),
    async logout() {
      try {
        const response = await logout();
        this.$store.dispatch("auth/logout");
      } catch (error) {
        // this.msg = error.response.data.message;
      }
    },
    getUser(id) {
      findById(id).then((response) => {
        this.SET_USER(response.data);
      });
    },
  },
};
</script>
